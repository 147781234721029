/* index.css */

/* Farbschema */
:root {
  --primary-color: #002f80;  /* Dunkelgrün */
  --secondary-color: #f2f2f2; /* Sehr helles Grau */
  --accent-color: #ffa500;   /* Orange für Akzente */
  --text-color: #333;        /* Dunkelgrau für Text */
  --text-light: #777;        /* Helleres Grau für sekundären Text */
  --sd-01 : #00064A;         /* Storydive dunkelblau */
  --sd-02 : #FF793D;         /* Storydive orange */
  --sd-03 : #FF5408;         /* Storydive rot */
  --sd-04 : #E7392F;         /* Storydive dunkelrot */
  --sd-05 : #69A1FB;         /* Storydive dunkelblau */
  --sd-06 : #1C2DB7;         /* Storydive dunkelblau */
  --sd-07 : #112282;         /* Storydive dunkelblau */
  --sd-08 : #69A1FB;         /* Storydive dunkelblau */
  --sd-09 : #FFC3B5;         /* Storydive dunkelblau */
  --sd-10 : #C4C9D1;         /* Storydive dunkelblau */
  --sd-11 : #B0BBF2;         /* Storydive dunkelblau */
  --sd-12 : #AAF2FF;         /* Storydive dunkelblau */
  --rating-yellow : #e0b300;
}

/* Body und HTML Standard-Styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: var(--secondary-color);
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

/* Einfache Reset-Regeln für Abstände in verschiedenen Elementen */
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

/* Header-Styling */
.header-footer {
  background-color: var(--sd-07);
  color: var(--sd-08);
  padding: 1rem;
  text-align: center;
}

/* Header-Styling */
.main-container {
  background-color: var(--sd-01);
  color: var(--sd-10);
  padding: 1rem;
  text-align: left;
  min-height:30rem
}

/* Navigation-Styling */
nav {
  display: flex;
  justify-content: center;
  padding: 1rem;
  background: var(--secondary-color);
}

nav a {
  text-decoration: none;
  color: var(--primary-color);
  margin: 0 1rem;
}

nav a:hover {
  color: var(--accent-color);
}

/* Hauptinhalt-Styling */
main {

}

/* Button-Styling */
button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  margin: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Stile für Geräte mit Touchscreen */
@media (hover: none) {
  button:active {
    background-color: var(--accent-color);
  }
}

/* Stile für Geräte mit Maus-Interaktion (nicht-touch) */
@media (hover: hover) {
  button:hover {
    background-color: 'green';
  }
}

/* Footer-Styling */
footer {

  background-color: #f5f5f5;
  padding: 10px 0;
  text-align: center;

}


/* Responsive Video und Canvas Container */
.video-container, .canvas-container {
  position: relative;
  width: 100%;
  max-width: 640px; /* Sie können dies an Ihre Bedürfnisse anpassen */
  margin: auto;
}

video, canvas {
  width: 100%;
  display: block; /* Entfernt den Standard-Inline-Block-Raum unterhalb des Videos */
}

/* Hilfsklassen */
.hidden {
  display: none;
}



header nav ul {
  list-style-type: none; /* Entfernt die Bullet Points der Liste */
  margin: 0;
  padding: 0;
  display: flex; /* Aktiviert Flexbox */
  align-items: center; /* Zentriert die Elemente vertikal */
}

header nav ul li {
  margin-right: 20px; /* Fügt rechts von jedem Listenelement einen Abstand hinzu */
}
/* Hier können Sie weitere spezifische Styles für Ihre Anwendung hinzufügen */


.sort-indicator {
  margin-left: 5px;
  display: inline-block;
  width: 0; 
  height: 0; 
  vertical-align: middle;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.sort-indicator.asc {
  border-bottom: 4px solid black; /* Pfeil nach oben */
}

.sort-indicator.desc {
  border-top: 4px solid black; /* Pfeil nach unten */
}

.sort-indicator.inactive {
  border-top: 4px solid lightgray; /* Inaktiver Pfeil */
}

/* bearbeiten/speichern icon */
.editicon {
  margin-left: 10px;
}
textarea {
  width: 90%;
}

.code {
  font-family: 'Courier New', monospace; /* oder eine andere Schriftart für Code */
  background-color: #f5f5f5; /* leichter Hintergrund */
  color: #333; /* dunkle Schriftfarbe */
}

.code-textarea {
  font-family: 'Courier New', monospace; /* oder eine andere Schriftart für Code */
  background-color: #f5f5f5; /* leichter Hintergrund */
  color: #333; /* dunkle Schriftfarbe */
  border: 1px solid #ddd;
  padding: 10px;
  white-space: pre; /* Behält Einrückungen und Zeilenbrüche bei */
}

#main-container {
  display: flex;
  flex-direction: column;
}

#content {
  flex: 1;
}

.editable-field{
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}


.notification-container {
    z-index: 1000;  /* ganz vorne! */
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 1rem;
    padding-bottom: 5rem;
}

.alert {
    margin-top: 0.5rem;
    opacity: 1;
    min-width: 10rem;
    word-wrap: break-word;
    max-width: 20rem;
    transition: transform 1s ease, opacity 1s ease;
}

@keyframes slideIn {
  from {
      transform: translateY(100%);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

.fade-out {
  animation: fadeOut 1s;
  animation-fill-mode: forwards;
  transform: translateX(-20%);
  
}

@keyframes fadeOut {
  from {
      opacity: 1;
  }
  to {
      opacity: 0;
  }
}

/* Stil für die Progressbar */
.upload-progressbar {

  width: 60%; /* Vollständige Breite des Containers */
  color: black; /* Dunklere Textfarbe */
  background-color: var(--sd-05); /* Farbe des Fortschrittsbalkens */
  opacity: 1;
}

.overlay {
  
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%; /* mehr als 100% da bein scrollen auf android unten sonst ein Rand entsteht */ 
  background-color: rgba(0,6,74, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  z-index: 99;  /* über allen Elementen */
  color : var(--secondary-color); /* Textfarbe */
  padding:5%;
  padding-bottom:20%;
}

.popup {
  
  position: fixed;
  top :5rem;
  width: 70%;

  background-color: rgba(0,6,74, 1);
  display: flex;
  justify-content: top;
  align-items: left;
  flex-direction: column;

  z-index: 90;  /* über allen Elementen */
  color : var(--secondary-color); /* Textfarbe */
  padding:5%;
  padding-bottom:20%;
}


.btn-deactivated {
  background-color: var(--sd-11); /* Grauer Hintergrund */
  color: rgb(255, 255, 255); /* Weiße Textfarbe */
  border: none;
  border-radius: .375rem;
}

.btn-deactivated:hover {
  background-color: var(--sd-10); /* Grüner Hintergrund */
  color: rgb(233, 233, 233); /* Weiße Textfarbe */
  cursor: not-allowed;
}

.card-image-container {
  position: relative;
}

.card-image {
  width: 100%;
  height: auto;
}

.main-player-cover-image{
  width: 70%;
  height: auto;
}

.main-player-title{
  margin-left: 15%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 5vw;
}

.pad5{
  padding: 5%;
}

.w15{
  width: 15%;
}

.w70{
  width: 70%;
}

.w100{
  width: 100%;
}

.ml0{
  margin-left: 0;
}

.mt1{
  margin-top: 1rem;
}

.mt2{
  margin-top: 2rem;
}

.mt5{
  margin-top: 5rem;
}

.mb5{
  margin-bottom: 5rem;
}

.ml15{
  margin-left: 15%;
}

.mr15{
  margin-right: 15%;
}

.text2{
  font-size: 2vw;
}


.text3{
  font-size: 3vw;
}

.text4{
  font-size: 4vw;
}


.text5{
  font-size: 5vw;
}
.text-left{
  text-align: left;
}
.text-right{
  text-align: right;
}

.rad03{
  border-radius: .375rem;
}

.main-audo-player{
  /* nur sichtbar für Admins und Tester */
  background-color: var(--sd-08);
  color: var(--sd-07);
  border-radius: 0.375rem;
}

.rhap_main-controls-button{
  color: var(--sd-01);
}
.rhap_volume-button {
  color: var(--sd-01);
}
.rhap_repeat-button{
  color: var(--sd-01);
  display: none;
}
.rhap_progress-indicator{
  background: var(--sd-01);
}
.rhap_volume-indicator{
  background: var(--sd-01);
}


.main-player-card{
  background: var(--sd-07);
  color: var(--secondary-color);
  border: none;
}

.main-player-feedback-container{
  display: flex;
  align-items: baseline;
  font-size: 3vw;
  margin-left: 15%;
  margin-right: 15%;
}

.main-player-rating{
  font-size: 4vw;
}

.bg-sec{
  background-color: var(--secondary-color);
}

.main-player-feedback{

  margin-left: auto;
}

.main-player-cover-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.main-player-cover-image.loading {
  min-height: 200px; /* Setzt eine Mindesthöhe, um Verzerrungen zu verhindern */
}

.main-player-audio-container {
  position: absolute;
  bottom: 10%; /* Beispiel: 10% Abstand von der Unterkante des Covers */
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}




.animated-logo-container {

  width:100%; /* Optional: Stellt sicher, dass der Container den gesamten Bildschirm einnimmt */
  padding:0px;
  margin:1rem;
}

.animated-logo-container object {
  position: absolute;
  top: 0; /* Positioniert das Logo am oberen Rand des Containers */
  right: 0; /* Positioniert das Logo am rechten Rand des Containers */
  width:15%;
}

.progress-b{
  min-width: 50%;
  position: absolute;
  bottom: 30%; /* Positioniert das Logo am oberen Rand des Containers */
}

.wait-ani{
  position: absolute;
  top: 20%; /* Positioniert das Logo am oberen Rand des Containers */
  width: 70%;
  max-height: 30%;
  text-align: center;
}
